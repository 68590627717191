<template>
  <main v-if="collections" class="publicationDetail">
    <BaseBanner />
    <section class="container publicationDetail__head">
      <div class="publicationDetail__introduction">
        <h2 class="publicationDetail__title">
          {{ collections.collections[0].title }}
        </h2>
        <table>
          <tr>
            <th class="publicationDetail__th">文物分類</th>
            <td>{{ collections.title }}</td>
          </tr>

          <tr>
            <th class="publicationDetail__th">主題類別</th>
            <td>{{ collections.type }}</td>
          </tr>

          <tr v-if="collections.collections[0].size">
            <th class="publicationDetail__th">尺寸描述</th>
            <td>
              {{ collections.collections[0].size }}
            </td>
          </tr>

          <!-- <tr>
            <th class="publicationDetail__th">委託詮釋研究</th>
            <td>陳逸偵</td>
          </tr> -->

          <tr>
            <th class="publicationDetail__th">登錄號</th>
            <td>{{ collections.collections[0].identifier }}</td>
          </tr>

          <tr>
            <th class="publicationDetail__th">典藏單位</th>
            <td>新北市立黃金博物館</td>
          </tr>
        </table>

        <div
          v-if="
            collections.collections[0].imageList &&
              collections.collections[0].imageList.length > 0
          "
          class="publicationDetail__carousel"
        >
          <carousel
            :perPage="3"
            :autoplay="false"
            :autoplayHoverPause="true"
            :paginationEnabled="false"
            :navigationEnabled="true"
            :navigationNextLabel="'>'"
            :navigationPrevLabel="'<'"
          >
            <slide
              v-for="img in collections.collections[0].imageList"
              :key="img"
            >
              <div class="publicationDetail__carouselContainer">
                <div
                  @click="currentImg = img"
                  class="publicationDetail__carouselImg clickable"
                  :style="`background-image: url(${img});`"
                ></div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>

      <div class="publicationDetail__imgContainer">
        <div
          :alt="`${collections.collections[0].title}`"
          class="publicationDetail__img"
          :style="`background-image:url(${currentImg});`"
        ></div>
      </div>
    </section>

    <section
      v-if="collections.collections[0].desc"
      class="container publicationDetail__desc"
    >
      <h3 class="publicationDetail__descTitle">商品描述</h3>

      <p>
        {{ collections.collections[0].desc }}
      </p>
    </section>

    <div class="publicationDetail__bg"></div>

    <GoBackFooter
      @click.stop.prevent.capture="onClickGoBack"
      :routeItem="{
        text: '返回典藏查詢',
        arrowString: '&#10229;',
        routeObj: { name: 'PublicationQuery' },
        useHistory: true
      }"
    />
  </main>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import BaseBanner from '@/components/BaseBanner.vue'
import GoBackFooter from '@/components/GoBackFooter.vue'

// Api
import { apiGetCollections } from '@/api/webAPI'

export default {
  components: { Carousel, Slide, BaseBanner, GoBackFooter },
  data() {
    return {
      collections: null,
      currentImg: null
    }
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.query
      if (!id) return this.$router.push({ name: 'Publication' })

      const collectionResponse = (
        await apiGetCollections({
          id
        })
      ).data.data

      if (collectionResponse.length === 0) {
        return this.$router.push({ name: 'Publication' })
      }
      this.currentImg = collectionResponse[0].collections[0].screenshot
      this.collections = collectionResponse[0]
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.publicationDetail {
  &__bg,
  &__descTitle {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__bg {
    background-image: url('~@/assets/image/PublicationDetail/Mountain.png');
    padding-bottom: 23.2%;
    margin-top: -15%;
  }

  &__head {
    display: flex;
    /* align-items: center; */
    background-color: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-top: -8.6rem;
    position: relative;
  }

  &__introduction {
    flex-grow: 1;
    padding: 5rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 124%;
    color: #595757;
    background-color: white;
  }

  &__title::before {
    content: '';
    width: 2.8rem;
    height: 1.9rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('~@/assets/image/TitleIcon.png');
    display: inline-block;
  }

  &__title {
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 124%;
    color: #595757;
    margin-bottom: 2.7rem;
  }

  &__imgContainer {
    background-color: white;
    width: 47%;
  }

  &__img {
    margin-left: auto;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }

  &__th {
    padding-bottom: 0.9rem;
    padding-right: 5rem;
    font-weight: 500;
    text-align: left;
  }

  &__desc {
    background: #f3f4f4;
    border: 1px solid #d0a805;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 150%;
    letter-spacing: 0.12em;
    position: relative;
    padding: 6.3rem 16rem;
    white-space: pre-line;
    text-align: justify;
  }

  &__descTitle {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('~@/assets/image/PublicationDetail/DescTitle.png');
    background-size: contain;
    width: 10rem;
    height: 10rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
  }

  &__carousel {
    margin-top: 1rem;
    max-width: 35rem;
  }

  &__carouselContainer {
    padding: 0 0.6rem;
  }

  &__carouselImg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 10rem;
    height: 10rem;
  }

  /* & .VueCarousel-navigation-button {
    width: 3rem;
    height: 3rem;
    background-color: #ebebeb;
    color: #646262;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}

@media (max-width: 768px) {
  .publicationDetail {
    &__head {
      flex-wrap: wrap;
    }

    &__introduction,
    &__imgContainer,
    &__img {
      width: 100%;
    }

    &__introduction {
      padding: 2rem;
    }

    &__img {
      max-width: 100%;
      padding-bottom: 80%;
      background-position: bottom;
    }

    &__desc {
      padding: 12rem 2rem 2rem 2rem;
    }
  }
}
</style>
