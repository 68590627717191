var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.collections)?_c('main',{staticClass:"publicationDetail"},[_c('BaseBanner'),_c('section',{staticClass:"container publicationDetail__head"},[_c('div',{staticClass:"publicationDetail__introduction"},[_c('h2',{staticClass:"publicationDetail__title"},[_vm._v(" "+_vm._s(_vm.collections.collections[0].title)+" ")]),_c('table',[_c('tr',[_c('th',{staticClass:"publicationDetail__th"},[_vm._v("文物分類")]),_c('td',[_vm._v(_vm._s(_vm.collections.title))])]),_c('tr',[_c('th',{staticClass:"publicationDetail__th"},[_vm._v("主題類別")]),_c('td',[_vm._v(_vm._s(_vm.collections.type))])]),(_vm.collections.collections[0].size)?_c('tr',[_c('th',{staticClass:"publicationDetail__th"},[_vm._v("尺寸描述")]),_c('td',[_vm._v(" "+_vm._s(_vm.collections.collections[0].size)+" ")])]):_vm._e(),_c('tr',[_c('th',{staticClass:"publicationDetail__th"},[_vm._v("登錄號")]),_c('td',[_vm._v(_vm._s(_vm.collections.collections[0].identifier))])]),_vm._m(0)]),(
          _vm.collections.collections[0].imageList &&
            _vm.collections.collections[0].imageList.length > 0
        )?_c('div',{staticClass:"publicationDetail__carousel"},[_c('carousel',{attrs:{"perPage":3,"autoplay":false,"autoplayHoverPause":true,"paginationEnabled":false,"navigationEnabled":true,"navigationNextLabel":'>',"navigationPrevLabel":'<'}},_vm._l((_vm.collections.collections[0].imageList),function(img){return _c('slide',{key:img},[_c('div',{staticClass:"publicationDetail__carouselContainer"},[_c('div',{staticClass:"publicationDetail__carouselImg clickable",style:(`background-image: url(${img});`),on:{"click":function($event){_vm.currentImg = img}}})])])}),1)],1):_vm._e()]),_c('div',{staticClass:"publicationDetail__imgContainer"},[_c('div',{staticClass:"publicationDetail__img",style:(`background-image:url(${_vm.currentImg});`),attrs:{"alt":`${_vm.collections.collections[0].title}`}})])]),(_vm.collections.collections[0].desc)?_c('section',{staticClass:"container publicationDetail__desc"},[_c('h3',{staticClass:"publicationDetail__descTitle"},[_vm._v("商品描述")]),_c('p',[_vm._v(" "+_vm._s(_vm.collections.collections[0].desc)+" ")])]):_vm._e(),_c('div',{staticClass:"publicationDetail__bg"}),_c('GoBackFooter',{attrs:{"routeItem":{
      text: '返回典藏查詢',
      arrowString: '&#10229;',
      routeObj: { name: 'PublicationQuery' },
      useHistory: true
    }},on:{"!click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickGoBack.apply(null, arguments)}}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"publicationDetail__th"},[_vm._v("典藏單位")]),_c('td',[_vm._v("新北市立黃金博物館")])])
}]

export { render, staticRenderFns }